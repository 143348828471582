.list {
  background: var(--white);
  width: 240px;
  padding: 5px 0 20px;
  box-shadow: var(--box-shadow-2);
  margin-right: 10px;
  border-radius: 3px;
}

.list__name {
  padding: 10px 5px 10px;
  color: var(--secondary-color);
  font-weight: bold;
}

.list__tasks-wrapper {
  padding: 10px 10px 5px;
  transition: background-color 0.2s ease;
  min-height: 80px;
  background: var(--tertiary-color);
  background: rgba(0, 0, 0, 0.03);
  margin-bottom: 20px;
}

.list__tasks-wrapper--dragging-over {
  background: var(--text-color);
}

.list__tasks-wrapper .task {
  margin-bottom: 10px;
}

.list__bottom {
  display: flex;
}

.list__delete button {
  font-size: 0.7em;
  font-weight: bold;
  margin-top: 30px;
}
