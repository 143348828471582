.card-plain {
  padding: 20px;
  background: var(--white);
  border: solid 2px var(--border-color);
  border-radius: var(--border-radius);
}

.card-plain--task-list {
  margin-right: 10px;
  padding: 0;
  /*background: var(--primary-color);*/
  border-color: transparent;
  /*background-image: linear-gradient(to top,#f3f3ff,#f3f3ff);*/
  box-shadow: var(--box-shadow-2);
}
