.header {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 0 20px;
  background: var(--white);
  height: 50px;
  border-bottom: solid 2px var(--border-color);
  margin-bottom: 20px;
}

.header a {
  margin-right: 20px;
}

.header__spacer {
  height: 50px;
  margin-bottom: 20px;
}
