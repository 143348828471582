:root {
  --bg-color: #f5f7f9;
  --white: white;
  --primary-color: #6161e8;
  --secondary-color: #224186;
  --tertiary-color: #f3f3ff;
  --text-color: #324063;
  --border-color: #e2e6ec;
  --border-radius: 4px;
  --box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
  --box-shadow-2: 0 10px 10px 0 rgba(50, 64, 99, 0.2);
  --box-shadow-3: 0 20px 30px 0 rgba(50, 64, 99, 0.2);
  --text-danger: #ee414b;
  --bp-sm: 768px;

  --col-width: 100%;
  --col-2-width: 100%;
  --col-3-width: 100%;
}

* {
  box-sizing: border-box;
}

.color-danger {
  color: #ee414b !important;
  color: var(--text-danger) !important;
}

@media screen and (min-width: 750px) {
  :root {
    --col-2-width: calc(50% - 5px);
    --col-3-width: calc(33.3333% - 5px);
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f5f7f9;
  background: var(--bg-color);
  font-size: 18px;
  color: #324063;
  color: var(--text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
}

a {
  color: #6161e8;
  color: var(--primary-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

input[type='text'],
input[type='email'],
input[type='password'] {
  font-size: 18px;
  box-shadow: none;
  height: 50px;
  border: solid 1px #e2e6ec;
  border: solid 1px var(--border-color);
  border-radius: 4px;
  border-radius: var(--border-radius);
  padding-left: 10px;
}

button {
  box-shadow: none;
  border: none;
  background: none;
  font-size: 16px;
  text-transform: uppercase;
}

button:hover {
  cursor: pointer;
}

.container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.col {
  width: 100%;
  width: var(--col-width);
}

.col-2 {
  width: 100%;
  width: var(--col-2-width);
}
.col-3 {
  width: 100%;
  width: var(--col-3-width);
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.justify-content-between {
  justify-content: space-between;
}

.link {
  text-transform: none;
  text-decoration: none;
  font-weight: bold;
}

.arrow {
  display: inline-block;
  margin-right: 5px;
  height: 8px;
  width: 8px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  border-top: solid 2px #6161e8;
  border-top: solid 2px var(--primary-color);
  border-left: solid 2px #6161e8;
  border-left: solid 2px var(--primary-color);
}

.button-link {
  color: #6161e8;
  color: var(--primary-color);
}

.font-weight-bold {
  font-weight: bold;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  margin-right: 20px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  margin: 10px;
  border: 5px solid #6161e8;
  border: 5px solid var(--primary-color);
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1)
    infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1)
    infinite;
  border-color: #6161e8 transparent transparent
    transparent;
  border-color: var(--primary-color) transparent transparent
    transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*.gu-mirror {*/
/*position: fixed !important;*/
/*margin: 0 !important;*/
/*z-index: 9999 !important;*/
/*opacity: 0.8;*/
/*-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";*/
/*filter: alpha(opacity=80);*/
/*}*/
/*.gu-hide {*/
/*display: none !important;*/
/*}*/
/*.gu-unselectable {*/
/*-webkit-user-select: none !important;*/
/*-moz-user-select: none !important;*/
/*-ms-user-select: none !important;*/
/*user-select: none !important;*/
/*}*/
/*.gu-transit {*/
/*opacity: 0.2;*/
/*-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";*/
/*filter: alpha(opacity=20);*/
/*}*/

.gu-mirror.gu-mirror {
  position: fixed;
  z-index: 9999;
  opacity: 0.8;
  cursor: pointer;
}

.gu-hide {
  display: none;
}

.gu-unselectable {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.gu-transit {
  opacity: 0.2;
}

.header {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 0 20px;
  background: var(--white);
  height: 50px;
  border-bottom: solid 2px var(--border-color);
  margin-bottom: 20px;
}

.header a {
  margin-right: 20px;
}

.header__spacer {
  height: 50px;
  margin-bottom: 20px;
}

.button-main {
  display: block;
  padding: 10px 30px;
  background: var(--primary-color);
  color: var(--white);
  border-radius: 5px;
  font-weight: bold;
}

.button-main:hover {
  cursor: pointer;
}

.button-main:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.button-main--secondary {
  background: var(--white);
  color: var(--primary-color);
  border: solid 2px var(--primary-color);
}

.form-login {
  padding: 50px 80px 70px;
  background: var(--white);
  box-shadow: var(--box-shadow-3);
  width: 100%;
  max-width: 500px;
  border-radius: 3px;
}

.form-login h1 {
  text-align: center;
}

.form-login__register {
  margin-top: 40px;
}

.form-auth {
}

.form-auth__fields input {
  display: block;
  width: 100%;
  margin-bottom: 20px;
}

.form-auth__error {
  margin-top: 20px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  color: var(--text-danger);
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-register {
  padding: 50px 80px 70px;
  background: var(--white);
  box-shadow: var(--box-shadow-3);
  width: 100%;
  max-width: 500px;
  border-radius: 3px;
}

.form-login h1 {
  text-align: center;
}

.register {
  display: flex;
  justify-content: center;
  align-items: center;
}

.board-card {
  font-size: 24px;
  font-weight: bold;
  background: var(--white);
  padding: 20px;
  border-radius: 3px;
  box-shadow: var(--box-shadow-2);
  color: var(--secondary-color);
  -webkit-transition: -webkit-transform 100ms ease;
  transition: -webkit-transform 100ms ease;
  transition: transform 100ms ease;
  transition: transform 100ms ease, -webkit-transform 100ms ease;
}

.board-card:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

.board-card__name {
  margin-bottom: 30px;
}

.board-card__delete-wrap {
  text-align: right;
}

.board-card__delete-button {
  border-radius: 3px;
  font-size: 12px;
  font-weight: bold;
  color: var(--text-danger);
  -webkit-transition: color 0.1s ease, background 0.1s ease;
  transition: color 0.1s ease, background 0.1s ease;
}

.board-card__delete-button:hover {
  background: var(--text-danger);
  color: var(--white);
}

.boards-list {
}

.boards-list__link {
  margin-bottom: 20px;
  text-decoration: none;
}


.cards-list {
  width: 300px;
  border: solid 1px red;
  height: 300px;
}

.cards-list-wrapper {
  display: flex;
}

.form-add-list {
  display: inline-block;
  background: rgba(0, 0, 0, 0.05);
  vertical-align: top;
  padding: 10px;
}

.form-add-list__fields {
  margin-bottom: 10px;
}

.list {
  background: var(--white);
  width: 240px;
  padding: 5px 0 20px;
  box-shadow: var(--box-shadow-2);
  margin-right: 10px;
  border-radius: 3px;
}

.list__name {
  padding: 10px 5px 10px;
  color: var(--secondary-color);
  font-weight: bold;
}

.list__tasks-wrapper {
  padding: 10px 10px 5px;
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
  min-height: 80px;
  background: var(--tertiary-color);
  background: rgba(0, 0, 0, 0.03);
  margin-bottom: 20px;
}

.list__tasks-wrapper--dragging-over {
  background: var(--text-color);
}

.list__tasks-wrapper .task {
  margin-bottom: 10px;
}

.list__bottom {
  display: flex;
}

.list__delete button {
  font-size: 0.7em;
  font-weight: bold;
  margin-top: 30px;
}

.card-plain {
  padding: 20px;
  background: var(--white);
  border: solid 2px var(--border-color);
  border-radius: var(--border-radius);
}

.card-plain--task-list {
  margin-right: 10px;
  padding: 0;
  /*background: var(--primary-color);*/
  border-color: transparent;
  /*background-image: linear-gradient(to top,#f3f3ff,#f3f3ff);*/
  box-shadow: var(--box-shadow-2);
}

.task {
  background: var(--white);
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.task--dragging {
  background: var(--border-color);
}

.task__delete {
  position: relative;
  margin-top: 20px;
  margin-left: auto;
  text-align: right;
  overflow: hidden;
  height: 20px;
  width: 80px;
  padding: 0;
}

.task__delete-button {
  position: absolute;
  right: -45px;
  -webkit-transition: right 0.2s ease;
  transition: right 0.2s ease;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  /*bottom: 0;*/
}

.task__delete-button:hover {
  cursor: pointer;
}

.task__delete:hover .task__delete-button {
  right: 0;
}

.task__delete-x {
  display: inline-flex;
  font-weight: bold;
  height: 6px;
  width: 6px;
  margin-right: 4px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--text-danger);
  border: solid 2px var(--text-danger);
}

.form-add-task {
  padding: 0 10px;
  margin-top: -20px;
}

.form-add-task__open {
  text-align: center;
}

.form-add-task__open:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
}

.form-add-task__fields input {
  height: 30px;
  width: calc(100% - 10px);
}

.form-add-task__submit:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.form-add-task__submit-wrap {
  text-align: right;
}

.form-add-task__submit {
  font-size: 12px;
  font-weight: bold;
  color: var(--primary-color);
  margin-left: auto;
}

.board-overview {
  padding: 0 20px 20px;
}

.board-overview__heading {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.board-overview h1 {
  margin: 0 30px 0 0;
}

.board-overview__back {
  display: inline-block;
  margin-bottom: 20px;
}

.board-overview__invite {
  font-size: 12px;
  font-weight: bold;
  color: var(--primary-color);
  margin-right: 30px;
  text-decoration: underline;
}

.user-list {
  position: relative;
  height: 45px;
}

.user-list__user {
  position: absolute;
  top: 0;
  width: 40px;
  height: 40px;
}

.user-list__avatar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98%;
  height: 98%;
  border-radius: 50%;
  color: white;
  font-size: 35px;
  z-index: 0;
  -webkit-transition: 0.1s ease;
  transition: 0.1s ease;
}

.user-list__detail {
  position: absolute;
  top: -20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  color: var(--primary-color);
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  visibility: hidden;
}

.user-list__user:hover .user-list__avatar {
  z-index: 1;
  -webkit-transform: translateY(-15px);
          transform: translateY(-15px);
}

.user-list__user:hover .user-list__detail {
  visibility: visible;
}

.form-invite-user {
}

.form-invite-user__fields {
  display: inline-block;
  width: 90%;
}

.form-invite-user__fields input {
  width: 100%;
  background: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: solid 2px var(--primary-color);
  padding: 10px 0;
  border-radius: 0;
  height: 15px;
  font-size: 12px;
}

.form-invite-user__submit {
  display: inline-flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  margin-left: 5px;
  padding: 0;
  background: var(--primary-color);
  color: white;
  border-radius: 50%;
}

