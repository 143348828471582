.task {
  background: var(--white);
  transition: background-color 0.2s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.task--dragging {
  background: var(--border-color);
}

.task__delete {
  position: relative;
  margin-top: 20px;
  margin-left: auto;
  text-align: right;
  overflow: hidden;
  height: 20px;
  width: 80px;
  padding: 0;
}

.task__delete-button {
  position: absolute;
  right: -45px;
  transition: right 0.2s ease;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  /*bottom: 0;*/
}

.task__delete-button:hover {
  cursor: pointer;
}

.task__delete:hover .task__delete-button {
  right: 0;
}

.task__delete-x {
  display: inline-flex;
  font-weight: bold;
  height: 6px;
  width: 6px;
  margin-right: 4px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--text-danger);
  border: solid 2px var(--text-danger);
}
