:root {
  --bg-color: #f5f7f9;
  --white: white;
  --primary-color: #6161e8;
  --secondary-color: #224186;
  --tertiary-color: #f3f3ff;
  --text-color: #324063;
  --border-color: #e2e6ec;
  --border-radius: 4px;
  --box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
  --box-shadow-2: 0 10px 10px 0 rgba(50, 64, 99, 0.2);
  --box-shadow-3: 0 20px 30px 0 rgba(50, 64, 99, 0.2);
  --text-danger: #ee414b;
  --bp-sm: 768px;

  --col-width: 100%;
  --col-2-width: 100%;
  --col-3-width: 100%;
}

* {
  box-sizing: border-box;
}

.color-danger {
  color: var(--text-danger) !important;
}

@media screen and (min-width: 750px) {
  :root {
    --col-2-width: calc(50% - 5px);
    --col-3-width: calc(33.3333% - 5px);
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--bg-color);
  font-size: 18px;
  color: var(--text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
}

a {
  color: var(--primary-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

input[type='text'],
input[type='email'],
input[type='password'] {
  font-size: 18px;
  box-shadow: none;
  height: 50px;
  border: solid 1px var(--border-color);
  border-radius: var(--border-radius);
  padding-left: 10px;
}

button {
  box-shadow: none;
  border: none;
  background: none;
  font-size: 16px;
  text-transform: uppercase;
}

button:hover {
  cursor: pointer;
}

.container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.col {
  width: var(--col-width);
}

.col-2 {
  width: var(--col-2-width);
}
.col-3 {
  width: var(--col-3-width);
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.justify-content-between {
  justify-content: space-between;
}

.link {
  text-transform: none;
  text-decoration: none;
  font-weight: bold;
}

.arrow {
  display: inline-block;
  margin-right: 5px;
  height: 8px;
  width: 8px;
  transform: rotate(-45deg);
  border-top: solid 2px var(--primary-color);
  border-left: solid 2px var(--primary-color);
}

.button-link {
  color: var(--primary-color);
}

.font-weight-bold {
  font-weight: bold;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  margin-right: 20px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  margin: 10px;
  border: 5px solid var(--primary-color);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1)
    infinite;
  border-color: var(--primary-color) transparent transparent
    transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*.gu-mirror {*/
/*position: fixed !important;*/
/*margin: 0 !important;*/
/*z-index: 9999 !important;*/
/*opacity: 0.8;*/
/*-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";*/
/*filter: alpha(opacity=80);*/
/*}*/
/*.gu-hide {*/
/*display: none !important;*/
/*}*/
/*.gu-unselectable {*/
/*-webkit-user-select: none !important;*/
/*-moz-user-select: none !important;*/
/*-ms-user-select: none !important;*/
/*user-select: none !important;*/
/*}*/
/*.gu-transit {*/
/*opacity: 0.2;*/
/*-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";*/
/*filter: alpha(opacity=20);*/
/*}*/

:global .gu-mirror.gu-mirror {
  position: fixed;
  z-index: 9999;
  opacity: 0.8;
  cursor: pointer;
}

:global .gu-hide {
  display: none;
}

:global .gu-unselectable {
  user-select: none;
}

:global .gu-transit {
  opacity: 0.2;
}
