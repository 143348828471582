.user-list {
  position: relative;
  height: 45px;
}

.user-list__user {
  position: absolute;
  top: 0;
  width: 40px;
  height: 40px;
}

.user-list__avatar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98%;
  height: 98%;
  border-radius: 50%;
  color: white;
  font-size: 35px;
  z-index: 0;
  transition: 0.1s ease;
}

.user-list__detail {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  color: var(--primary-color);
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  visibility: hidden;
}

.user-list__user:hover .user-list__avatar {
  z-index: 1;
  transform: translateY(-15px);
}

.user-list__user:hover .user-list__detail {
  visibility: visible;
}
