.form-invite-user {
}

.form-invite-user__fields {
  display: inline-block;
  width: 90%;
}

.form-invite-user__fields input {
  width: 100%;
  background: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: solid 2px var(--primary-color);
  padding: 10px 0;
  border-radius: 0;
  height: 15px;
  font-size: 12px;
}

.form-invite-user__submit {
  display: inline-flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  margin-left: 5px;
  padding: 0;
  background: var(--primary-color);
  color: white;
  border-radius: 50%;
}
