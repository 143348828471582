.form-auth {
}

.form-auth__fields input {
  display: block;
  width: 100%;
  margin-bottom: 20px;
}

.form-auth__error {
  margin-top: 20px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  color: var(--text-danger);
}
