.form-register {
  padding: 50px 80px 70px;
  background: var(--white);
  box-shadow: var(--box-shadow-3);
  width: 100%;
  max-width: 500px;
  border-radius: 3px;
}

.form-login h1 {
  text-align: center;
}
