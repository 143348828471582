.board-overview {
  padding: 0 20px 20px;
}

.board-overview__heading {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.board-overview h1 {
  margin: 0 30px 0 0;
}

.board-overview__back {
  display: inline-block;
  margin-bottom: 20px;
}

.board-overview__invite {
  font-size: 12px;
  font-weight: bold;
  color: var(--primary-color);
  margin-right: 30px;
  text-decoration: underline;
}
